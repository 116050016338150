import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { FirstScreen, SpecsBox, CompatiBox, SDKBox, DeveloperSay, JoinUs, FAQBox, 
    BuyButton ,
    Building} from '@/components/Ultra'
import ProcessNav from '@/components/process'
import { useIntl } from "react-intl"
import { useInView } from "react-intersection-observer"
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'

const Index = () => {
    const [processShowId, setProcessShowId] = useState(0)
    const [btnshow, ifBtnShow] = useState(false)
    const intl = useIntl()
    const [block1Ref, block1Inview] = useInView({ threshold: 0 });
    const [block2Ref, block2Inview] = useInView({ threshold: 0 });
    const [block3Ref, block3Inview] = useInView({ threshold: 0 });
    const region = getCountry()
    const ultra_shop_link=buy_link[region]['air2ultra'].shop
  
    const navList_en = [
        {
            id: 0,
            title: 'Air 2 Ultra',
            classname: "ces-air2ultra",
        },
        {
            id: 1,
            title: intl.formatMessage({ id: "ces.specs.title", defaultMessage: "Specs" }),
            classname: "ces-specs",
        },
        {
            id: 2,
            title: intl.formatMessage({ id: "ces.progressBar.desc5", defaultMessage: "Compatibility" }),
            classname: "ces-compatibility",
        },
        {
            id: 3,
            title: 'SDK',
            classname: "ces-SDK",
        },
        {
            id: 4,
            title: intl.formatMessage({ id: "ces.progressBar.desc7", defaultMessage: "Developer" }),
            classname: "ces-developer",
        },
        // {
        //   id: 5,
        //   title: 'XREAL Light',
        //   classname: "ces-xreallight",
        // },
        {
            id: 5,
            title: 'FAQ',
            classname: "ces-faq"
        }
    ]
    const navList_else = [
        {
            id: 0,
            title: 'Air 2 Ultra',
            classname: "ces-air2ultra",
        },
        {
            id: 1,
            title: intl.formatMessage({ id: "ces.specs.title", defaultMessage: "Specs" }),
            classname: "ces-specs",
        },
        {
            id: 2,
            title: intl.formatMessage({ id: "ces.progressBar.desc5", defaultMessage: "Compatibility" }),
            classname: "ces-compatibility",
        },
        {
            id: 3,
            title: 'SDK',
            classname: "ces-SDK",
        },
        {
            id: 4,
            title: intl.formatMessage({ id: "ces.progressBar.desc7", defaultMessage: "Developer" }),
            classname: "ces-developer",
        }, {
            id: 5,
            title: 'FAQ',
            classname: "ces-faq"
        }
    ]
    const navList = intl.locale == 'en' ? navList_en : navList_else
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
    useEffect(() => {
        if (processShowId > 0) {
            ifBtnShow(true)
        } else {
            ifBtnShow(false)
        }
    }, [processShowId])

    const getVisibleArea = (element) => {
        const rect = element.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const visibleHeight = Math.min(rect.bottom, viewportHeight) - Math.max(rect.top, 0);
        return Math.max(visibleHeight, 0);
    };
    const handleScroll = () => {
        const classAllArr = navList.map(item => `.${item.classname}`);
        const classAll = classAllArr.join(',')
        const divElements = document.querySelectorAll(classAll);

        let maxVisibleArea = 0;
        let divWithMostVisibleArea = null;

        divElements.forEach((div) => {
            const visibleArea = getVisibleArea(div);

            if (visibleArea > maxVisibleArea) {
                maxVisibleArea = visibleArea;
                divWithMostVisibleArea = div;
            }
        });
        if (!!divWithMostVisibleArea) {
            const showDiv = navList.filter(item => item.classname == divWithMostVisibleArea.className)
            setProcessShowId(showDiv[0].id)
        }

    };

    return (
        <Layout menuBlack={(block1Inview || (block2Inview && !block3Inview)) ? false : true}>
            <SEO page="Air 2 Ultra" />
            <div style={{ position: "relative" }}>
             
                <ProcessNav processShowId={processShowId} list={navList} />
                <BuyButton ifShow={btnshow} ultra_link={ultra_shop_link}/>
                <div className="ces-air2ultra" ref={block1Ref}>
                    <FirstScreen ultra_link={ultra_shop_link}/>
                </div>
                <div ref={block3Ref}>
                    <div className="ces-specs" >
                        <SpecsBox />
                    </div>
                    <div className="ces-compatibility">
                        <CompatiBox />
                    </div>
                </div>

                <div className="ces-SDK">
                    <div ref={block2Ref}> <SDKBox /></div>
                    <Building />
                    <DeveloperSay />
                </div>

                <div className='ces-developer'>
                    <JoinUs />
                </div>
                
                <div className="ces-faq">
                    <FAQBox />
                </div>
            </div>

        </Layout>
    )
}
export default Index