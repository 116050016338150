import React, { useEffect } from 'react'
import style from './index.module.less'
import useGa from "@/hooks/useGa"

const BuyButton = ({ ifShow, ultra_link }) => {
   
    return (
        <div className={style.btn} style={{ opacity: ifShow ? 1 : 0 }} onClick={() => {
            window.open(ultra_link),
            useGa('Home', 'developer_home_cons_buy_button', "Click")
            sensors.track('module_interaction', {
                custom: {
                    module_id: 20062
                }
            });
        }}>
            Buy
        </div>
    )
}

export default BuyButton